<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="'modules.entity.asset.fields.name.label' | translate: 'Name'"
        [placeholder]="'modules.entity.asset.fields.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>

    <div *ngxPermissionsOnly="mapperService?.mappedFields['organization']?.permissions" class="col-12 md:col-6">
      <app-target-code-selector
        [label]="'modules.entity.asset.fields.organization.label' | translate: 'Organization'"
        [placeholder]="'modules.entity.asset.fields.organization.placeholder' | translate: 'Search Organization'"
        [excludeCodes]="itemId ? [itemId] : []"
        [control]="formGroup?.controls?.organization"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['ORGANIZATION']"
      >
      </app-target-code-selector>
    </div>
    <!-- <div *ngxPermissionsOnly="mapperService?.mappedFields['responsibility']?.permissions" class="col-12 md:col-6">
    <app-target-code-selector
      [label]="'Responsibility'"
      [placeholder]="'Search Responsibilities'"
      [control]="formGroup?.controls?.responsibility"
      [viewMode]="fieldViewMode"
      [multi]="false"
      [optionLabel]="'name'"
      [optionValue]="'code'"
      (onChangesObject)="onSelectResponsibilityObj($event)"
      [customProjectionFields]="['code','recordStatus','label','name','color','icon','fields','options','key','type','groupName']"
      [targetTypes]="['RESPONSIBILITY']">
    </app-target-code-selector>
  </div> -->
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="'modules.entity.asset.fields.owner.label' | translate: 'Owner'"
        [placeholder]="'modules.entity.asset.fields.owner.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.owner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div *ngxPermissionsOnly="mapperService?.mappedFields['type']?.permissions" class="col-12 md:col-6">
      <app-target-code-selector
        [label]="'modules.entity.asset.fields.entityType.label' | translate: 'Entity Type'"
        [placeholder]="'modules.entity.asset.fields.entityType.placeholder' | translate: 'Search...'"
        [control]="formGroup?.controls?.type"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="undefined"
        [dataKey]="'code'"
        [extraFilters]="this.assetsList ? [{ property: 'category', operation: 'IN', value: this.assetsList }] : []"
        (onChanges)="onEntityTypeChange($event)"
        [customProjectionFields]="[
          'code',
          'recordStatus',
          'label',
          'name',
          'color',
          'icon',
          'fields',
          'options',
          'key',
          'type',
        ]"
        [targetTypes]="['ENTITY_TYPE']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="'modules.entity.asset.fields.parent.label' | translate: 'Parent'"
        [placeholder]="'modules.entity.asset.fields.parent.placeholder' | translate: 'Search...'"
        [excludeCodes]="itemId ? [itemId] : []"
        [control]="formGroup?.controls?.parent"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['ASSET']"
        [extraFilters]="
          formGroup?.controls?.organization?.value
            ? [{ property: 'organization', operation: 'EQUAL', value: formGroup?.controls?.organization?.value }]
            : []
        "
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'description'"
        [label]="'modules.entity.asset.fields.description.label' | translate: 'Description'"
        [placeholder]="'modules.entity.asset.fields.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
    <div class="col-12">
      <app-dynamic-field-list-input
        [label]="'modules.entity.asset.fields.dynamics.label' | translate: 'Dynamic Fields'"
        [placeholder]="'modules.entity.asset.fields.dynamics.placeholder' | translate: 'Dynamic Fields'"
        [control]="formGroup?.controls?.dynamics"
        [viewMode]="fieldViewMode"
        [config]="{
          dynamicFields:
            formGroup?.controls?.type?.value?.code != data?.type
              ? formGroup?.controls?.type?.value?.fields
              : data?.entityType?.fields,
          dynamicFieldValues: formGroup?.controls?.type?.value?.code != data?.type ? null : data?.dynamics,
        }"
      >
      </app-dynamic-field-list-input>
    </div>
  </div>
  <!-- <ng-content></ng-content> -->
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
